import { default as _91_46_46_46all_93NYTK3uYXC4Meta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/[...all].vue?macro=true";
import { default as indexwyxK25MsmTMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/about/index.vue?macro=true";
import { default as activityh7J8BzXnaxMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/activity.vue?macro=true";
import { default as companyEd6zX6wACVMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/company.vue?macro=true";
import { default as contactdvQTEoWlaRMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/contact.vue?macro=true";
import { default as downloadwnY3OCC72jMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/download.vue?macro=true";
import { default as forgetpwHE24pJ9O2xMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/forgetpw.vue?macro=true";
import { default as full_delegationF61Xf5cqHFMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/full_delegation.vue?macro=true";
import { default as fund_detail2IYaS2enXsMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/fund_detail.vue?macro=true";
import { default as fund_productO7ZhXQovWkMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/fund_product.vue?macro=true";
import { default as fundIFHwaHyxoNMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/fund.vue?macro=true";
import { default as futures_tradesdSeGqARdQMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/futures_trade.vue?macro=true";
import { default as futuresDe76r4hqLYMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/futures.vue?macro=true";
import { default as global_detail4U5i84NBNlMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/global_detail.vue?macro=true";
import { default as global_investmenttKD4q4gkxxMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/global_investment.vue?macro=true";
import { default as _91id_93WulI3XrqdJMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/hi/[id].vue?macro=true";
import { default as hometelhOF3rBPMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/home.vue?macro=true";
import { default as indexueOwotueFiMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/index.vue?macro=true";
import { default as insurance_detailgA1SjGS58ZMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/insurance_detail.vue?macro=true";
import { default as insurance6PYD6RE4k4Meta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/insurance.vue?macro=true";
import { default as investment7okFo8xhBCMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/investment.vue?macro=true";
import { default as investmentReaderDetailblz1ZSX2gxMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/investmentReaderDetail.vue?macro=true";
import { default as market_analysisVIHnxnM7AfMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/market_analysis.vue?macro=true";
import { default as newstatementsRKVlm7HAQjMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/newstatements.vue?macro=true";
import { default as detailSXwf0zk1i9Meta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/notice/detail.vue?macro=true";
import { default as service_detailXv4UBjyvOYMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/service_detail.vue?macro=true";
import { default as serviceR4eT3ACUKuMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/service.vue?macro=true";
import { default as stock_trade1aqV4vsO3uMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/stock_trade.vue?macro=true";
import { default as stock9XSW8BTyBmMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/stock.vue?macro=true";
import { default as teacherdetailpqVga7JoSvMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/teacherdetail.vue?macro=true";
import { default as wantaisz85BSjkYWMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/wantai.vue?macro=true";
import { default as wealth_planningYeyPSkE7KsMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/wealth_planning.vue?macro=true";
import { default as component_45stub0xbS9OgnhrMeta } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/node_modules/.pnpm/nuxt@3.13.1_eslint-ts-patch@8.56.0-0_less@3.13.1_rollup@4.21.2_typescript@5.5.4_vite@5.4.3_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub0xbS9Ognhr } from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/node_modules/.pnpm/nuxt@3.13.1_eslint-ts-patch@8.56.0-0_less@3.13.1_rollup@4.21.2_typescript@5.5.4_vite@5.4.3_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "all___hk",
    path: "/hk/:all(.*)*",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: "all___cn",
    path: "/:all(.*)*",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: "about___hk",
    path: "/hk/about",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___cn",
    path: "/about",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "activity___hk",
    path: "/hk/activity",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/activity.vue").then(m => m.default || m)
  },
  {
    name: "activity___cn",
    path: "/activity",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/activity.vue").then(m => m.default || m)
  },
  {
    name: "company___hk",
    path: "/hk/company",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/company.vue").then(m => m.default || m)
  },
  {
    name: "company___cn",
    path: "/company",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/company.vue").then(m => m.default || m)
  },
  {
    name: "contact___hk",
    path: "/hk/contact",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___cn",
    path: "/contact",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "download___hk",
    path: "/hk/download",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/download.vue").then(m => m.default || m)
  },
  {
    name: "download___cn",
    path: "/download",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/download.vue").then(m => m.default || m)
  },
  {
    name: "forgetpw___hk",
    path: "/hk/forgetpw",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/forgetpw.vue").then(m => m.default || m)
  },
  {
    name: "forgetpw___cn",
    path: "/forgetpw",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/forgetpw.vue").then(m => m.default || m)
  },
  {
    name: "full_delegation___hk",
    path: "/hk/full_delegation",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/full_delegation.vue").then(m => m.default || m)
  },
  {
    name: "full_delegation___cn",
    path: "/full_delegation",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/full_delegation.vue").then(m => m.default || m)
  },
  {
    name: "fund_detail___hk",
    path: "/hk/fund_detail",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/fund_detail.vue").then(m => m.default || m)
  },
  {
    name: "fund_detail___cn",
    path: "/fund_detail",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/fund_detail.vue").then(m => m.default || m)
  },
  {
    name: "fund_product___hk",
    path: "/hk/fund_product",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/fund_product.vue").then(m => m.default || m)
  },
  {
    name: "fund_product___cn",
    path: "/fund_product",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/fund_product.vue").then(m => m.default || m)
  },
  {
    name: "fund___hk",
    path: "/hk/fund",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/fund.vue").then(m => m.default || m)
  },
  {
    name: "fund___cn",
    path: "/fund",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/fund.vue").then(m => m.default || m)
  },
  {
    name: "futures_trade___hk",
    path: "/hk/futures_trade",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/futures_trade.vue").then(m => m.default || m)
  },
  {
    name: "futures_trade___cn",
    path: "/futures_trade",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/futures_trade.vue").then(m => m.default || m)
  },
  {
    name: "futures___hk",
    path: "/hk/futures",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/futures.vue").then(m => m.default || m)
  },
  {
    name: "futures___cn",
    path: "/futures",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/futures.vue").then(m => m.default || m)
  },
  {
    name: "global_detail___hk",
    path: "/hk/global_detail",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/global_detail.vue").then(m => m.default || m)
  },
  {
    name: "global_detail___cn",
    path: "/global_detail",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/global_detail.vue").then(m => m.default || m)
  },
  {
    name: "global_investment___hk",
    path: "/hk/global_investment",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/global_investment.vue").then(m => m.default || m)
  },
  {
    name: "global_investment___cn",
    path: "/global_investment",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/global_investment.vue").then(m => m.default || m)
  },
  {
    name: "hi-id___hk",
    path: "/hk/hi/:id()",
    meta: _91id_93WulI3XrqdJMeta || {},
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/hi/[id].vue").then(m => m.default || m)
  },
  {
    name: "hi-id___cn",
    path: "/hi/:id()",
    meta: _91id_93WulI3XrqdJMeta || {},
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/hi/[id].vue").then(m => m.default || m)
  },
  {
    name: "home___hk",
    path: "/hk/home",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "home___cn",
    path: "/home",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index___hk",
    path: "/hk",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___cn",
    path: "/",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "insurance_detail___hk",
    path: "/hk/insurance_detail",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/insurance_detail.vue").then(m => m.default || m)
  },
  {
    name: "insurance_detail___cn",
    path: "/insurance_detail",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/insurance_detail.vue").then(m => m.default || m)
  },
  {
    name: "insurance___hk",
    path: "/hk/insurance",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/insurance.vue").then(m => m.default || m)
  },
  {
    name: "insurance___cn",
    path: "/insurance",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/insurance.vue").then(m => m.default || m)
  },
  {
    name: "investment___hk",
    path: "/hk/investment",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/investment.vue").then(m => m.default || m)
  },
  {
    name: "investment___cn",
    path: "/investment",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/investment.vue").then(m => m.default || m)
  },
  {
    name: "investmentReaderDetail___hk",
    path: "/hk/investmentReaderDetail",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/investmentReaderDetail.vue").then(m => m.default || m)
  },
  {
    name: "investmentReaderDetail___cn",
    path: "/investmentReaderDetail",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/investmentReaderDetail.vue").then(m => m.default || m)
  },
  {
    name: "market_analysis___hk",
    path: "/hk/market_analysis",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/market_analysis.vue").then(m => m.default || m)
  },
  {
    name: "market_analysis___cn",
    path: "/market_analysis",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/market_analysis.vue").then(m => m.default || m)
  },
  {
    name: "newstatements___hk",
    path: "/hk/newstatements",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/newstatements.vue").then(m => m.default || m)
  },
  {
    name: "newstatements___cn",
    path: "/newstatements",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/newstatements.vue").then(m => m.default || m)
  },
  {
    name: "notice-detail___hk",
    path: "/hk/notice/detail",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/notice/detail.vue").then(m => m.default || m)
  },
  {
    name: "notice-detail___cn",
    path: "/notice/detail",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/notice/detail.vue").then(m => m.default || m)
  },
  {
    name: "service_detail___hk",
    path: "/hk/service_detail",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/service_detail.vue").then(m => m.default || m)
  },
  {
    name: "service_detail___cn",
    path: "/service_detail",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/service_detail.vue").then(m => m.default || m)
  },
  {
    name: "service___hk",
    path: "/hk/service",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/service.vue").then(m => m.default || m)
  },
  {
    name: "service___cn",
    path: "/service",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/service.vue").then(m => m.default || m)
  },
  {
    name: "stock_trade___hk",
    path: "/hk/stock_trade",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/stock_trade.vue").then(m => m.default || m)
  },
  {
    name: "stock_trade___cn",
    path: "/stock_trade",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/stock_trade.vue").then(m => m.default || m)
  },
  {
    name: "stock___hk",
    path: "/hk/stock",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/stock.vue").then(m => m.default || m)
  },
  {
    name: "stock___cn",
    path: "/stock",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/stock.vue").then(m => m.default || m)
  },
  {
    name: "teacherdetail___hk",
    path: "/hk/teacherdetail",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/teacherdetail.vue").then(m => m.default || m)
  },
  {
    name: "teacherdetail___cn",
    path: "/teacherdetail",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/teacherdetail.vue").then(m => m.default || m)
  },
  {
    name: "wantai___hk",
    path: "/hk/wantai",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/wantai.vue").then(m => m.default || m)
  },
  {
    name: "wantai___cn",
    path: "/wantai",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/wantai.vue").then(m => m.default || m)
  },
  {
    name: "wealth_planning___hk",
    path: "/hk/wealth_planning",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/wealth_planning.vue").then(m => m.default || m)
  },
  {
    name: "wealth_planning___cn",
    path: "/wealth_planning",
    component: () => import("/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/pages/wealth_planning.vue").then(m => m.default || m)
  },
  {
    name: component_45stub0xbS9OgnhrMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub0xbS9Ognhr
  }
]