import revive_payload_client_qMcvmFr748 from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/node_modules/.pnpm/nuxt@3.13.1_eslint-ts-patch@8.56.0-0_less@3.13.1_rollup@4.21.2_typescript@5.5.4_vite@5.4.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Z1DyNd7NW9 from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/node_modules/.pnpm/nuxt@3.13.1_eslint-ts-patch@8.56.0-0_less@3.13.1_rollup@4.21.2_typescript@5.5.4_vite@5.4.3_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_HJAcd94bem from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/node_modules/.pnpm/nuxt@3.13.1_eslint-ts-patch@8.56.0-0_less@3.13.1_rollup@4.21.2_typescript@5.5.4_vite@5.4.3_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_J7ey0ktP4P from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/node_modules/.pnpm/nuxt-site-config@2.2.16_rollup@4.21.2_vite@5.4.3_vue@3.5.1/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import navigation_repaint_client_p7mZp2kfds from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/node_modules/.pnpm/nuxt@3.13.1_eslint-ts-patch@8.56.0-0_less@3.13.1_rollup@4.21.2_typescript@5.5.4_vite@5.4.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ki1TfPx8cE from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/node_modules/.pnpm/nuxt@3.13.1_eslint-ts-patch@8.56.0-0_less@3.13.1_rollup@4.21.2_typescript@5.5.4_vite@5.4.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_79lfifUWFf from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/node_modules/.pnpm/nuxt@3.13.1_eslint-ts-patch@8.56.0-0_less@3.13.1_rollup@4.21.2_typescript@5.5.4_vite@5.4.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_TOyYumG5BL from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@4.21.2_typescript@5.5.4_vue@3.5.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/.nuxt/components.plugin.mjs";
import prefetch_client_VCP5M8eBIK from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/node_modules/.pnpm/nuxt@3.13.1_eslint-ts-patch@8.56.0-0_less@3.13.1_rollup@4.21.2_typescript@5.5.4_vite@5.4.3_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/.nuxt/element-plus-injection.plugin.mjs";
import plugin_yNSXrsVcWR from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/node_modules/.pnpm/dayjs-nuxt@2.1.11_rollup@4.21.2/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import switch_locale_path_ssr_raey1wUcRQ from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_rollup@4.21.2_vue@3.5.1/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_tt7YzRWLKH from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_rollup@4.21.2_vue@3.5.1/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import pwa_client_JSgke8fngs from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/node_modules/.pnpm/@vite-pwa+nuxt@0.4.0_rollup@4.21.2_vite@5.4.3_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_client_yPDkOh7NJh from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_rollup@4.21.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/.nuxt/unocss.mjs";
import vuedompurify_x8InOr8ZUv from "/Users/cjn/Desktop/jiufangproject/www-forthright/fd-new-official/plugins/vuedompurify.js";
export default [
  revive_payload_client_qMcvmFr748,
  unhead_Z1DyNd7NW9,
  router_HJAcd94bem,
  _0_siteConfig_J7ey0ktP4P,
  navigation_repaint_client_p7mZp2kfds,
  check_outdated_build_client_ki1TfPx8cE,
  chunk_reload_client_79lfifUWFf,
  plugin_vue3_TOyYumG5BL,
  components_plugin_KR1HBZs4kY,
  prefetch_client_VCP5M8eBIK,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  plugin_yNSXrsVcWR,
  switch_locale_path_ssr_raey1wUcRQ,
  i18n_tt7YzRWLKH,
  pwa_client_JSgke8fngs,
  plugin_client_yPDkOh7NJh,
  unocss_MzCDxu9LMj,
  vuedompurify_x8InOr8ZUv
]