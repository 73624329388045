<script setup>
import { appName } from '~/constants'
useNuxtApp().payload.serverRendered = false //不要动，动了项目就完蛋
useHead({
  title: appName,
})
if (process.client) {
  const base_size = 375 / (375 / 100)
  function setRem() {
    const width = document.documentElement.clientWidth
    if (width <= 500) {
      const scale = width / 375
      document.documentElement.style.fontSize = `${(base_size * Math.min(scale, 2)) / 25}px`
    }
  }
  window.onresize = function () {
    setRem()
  }
  setRem()
}
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style>
html {
  font-family: PingFangSC, PingFang SC;
}

@font-face {
  font-family: 'DIN';
  src: url('@/assets/din.ttf');
}

.mb {
  display: none;
}

.pc {
  display: block;
}

/* Small devices (landscape phones, 640px and up) */
@media (min-width: 0px) and (max-width: 640px) {
  html {
    font-size: 4px;
  }

  .mb {
    display: block;
  }

  .pc {
    display: none;
  }

  html .tabs .ant-tabs .ant-tabs-tab {
    font-size: 4rem;
    font-weight: 600;
  }

  html .tabs2 .ant-tabs .ant-tabs-tab {
    font-size: 3.5rem;
    font-weight: 400;
  }

  html .tabs2 .ant-tabs-nav {
    overflow-x: auto;
    width: 100%;
    padding-left: 3.75rem;
  }

  html .tabs3 .ant-tabs .ant-tabs-tab {
    font-size: 3.5rem;
    font-weight: 400;
  }

  html .tabs3 .ant-tabs-nav {
    overflow-x: auto;
    width: 100%;
    padding-left: 0;
  }

  html .tabs3 .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 20px;
  }


  html .ant-form-item {
    margin-bottom: 12px;
  }

  html .ant-drawer .ant-drawer-body {
    padding: 0;
  }
}
@media (min-width: 430px) and (max-width: 640px) {
  html {
    font-size: 2.4px;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  html {
    font-size: 2.8px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1023px) {
  html {
    font-size: 3.2px;
  }
}

/* Large devices (desktops, 1024px and up) */
@media (min-width: 1024px) and (max-width: 1279px) {
  html {
    font-size: 3.2px;
  }
}

/* Extra large devices (large desktops, 1280px and up) */
@media (min-width: 1280px) and (max-width: 1535px) {
  html {
    font-size: 4px;
  }
}

/* Extra extra large devices (extra large desktops, 1536px and up) */
@media (min-width: 1536px) {
  html {
    font-size: 4px;
  }
}

.text_active[data-hover="true"] {
  position: relative;
}

.text_active[data-hover="true"]::after {
  content: '';
  width: 100%;
  height: 0.75rem;
  background: #AA7322;
  position: absolute;
  bottom: -2.5rem;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
}

.text_active[data-hover="true"]:hover::after {
  opacity: 1;
}

.text_active_path {
  position: relative;
}

.text_active_path ::after {
  content: '';
  width: 100%;
  height: 0.75rem;
  background: #AA7322;
  position: absolute;
  bottom: -2.5rem;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 1;
}

.group-active[data-hover="true"]::after {
  content: '';
  width: 100%;
  height: 100%;
  background: #AA7322;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
}

.group-active[data-hover="true"]:hover::after {
  opacity: 0.5;
}

.tabs .ant-tabs .ant-tabs-tab {
  font-size: 7rem;
  font-weight: 600;
}

.tabs .ant-tabs-top>.ant-tabs-nav::before {
  display: none;
}

.tabs2 .ant-tabs .ant-tabs-tab {
  font-size: 4.5rem;
  font-weight: 400;
}

.tabs2 .ant-tabs-top>.ant-tabs-nav::before {
  display: none;
}

.tabs2 .ant-tabs-nav {
  width: 300rem;
  margin: 0 auto;
}



.ant-btn-primary {
  background-color: #aa7322 !important;
}

.ant-input {
  border-radius: 0 !important;
}

.ant-btn {
  border-radius: 0 !important;
}

.ant-checkbox .ant-checkbox-inner {
  border-radius: 0 !important;
}

.ant-input-affix-wrapper {
  border-radius: 0 !important;
}

.ant-select-selector {
  border-radius: 0 !important;
}

.ant-select[hasbg] .ant-select-selector{
  background-color: #213048 !important;
  color: white
}

.ant-select[hasbg] .ant-select-selection-item ,
.ant-select[hasbg] .ant-select-arrow,
.ant-select[hasbg] .ant-select-selection-placeholder,
.ant-form[hasbg] label{
  color: white
}
.ant-select-dropdown {
  border-radius: 0 !important;
  margin-left: 1px;
}

.blurred-content {
  filter: blur(10px);
  /* 标准语法，现代浏览器支持 */
  -webkit-filter: blur(10px);
  /* WebKit 内核的浏览器支持 */
}
</style>
